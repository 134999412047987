import React from "react";
import {
  SEO,
  MarkdownContent,
  BasicCarousel,
  componentIterator,
  Image,
  DynamicForm,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class BridalPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      fishermanWebsiteComponent,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row className={""} style={{ padding: 8 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 8 }}
              width={10}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "f5d9efe7-5509-401b-a7de-5529a3626edc",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <BasicCarousel
                  className={"image-block"}
                  style={{}}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "BasicCarousel",
                      componentIdentifier:
                        "64c20cb6-bf12-42aa-b5f0-2303e0960baa",
                    }),
                    component: <Image className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "56f6c5f1-cd92-4acc-a8bc-30300db2774a",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <BasicCarousel
                  className={"image-block"}
                  style={{}}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "BasicCarousel",
                      componentIdentifier:
                        "8f30fa17-cf8d-4624-9bc3-1b03b197ff1a",
                    }),
                    component: <Image className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={1243}
                  businessInfoId={6432}
                  websiteId={7476}
                  locationId={1309}
                  componentConfigurationId={46080}
                  header={fishermanWebsiteComponent.data.header}
                  fields={fishermanWebsiteComponent.data.dynamicFormFields}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 22510 }) {
      title
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
    fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "30bc918f-57ce-42e4-acb5-8b2ff85acf2a" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
        }
      }
    }
  }
`;
